import { render, staticRenderFns } from "./Informacion.vue?vue&type=template&id=5b4e77d0&"
import script from "./Informacion.vue?vue&type=script&lang=js&"
export * from "./Informacion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports